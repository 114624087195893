import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import map from "lodash/map"
import uniq from "lodash/uniq"

const IndexPage = ({ data: { eva, jonas } }) => {
  let evaCount = uniq(map(eva.nodes, "EXIF.DateCreatedISO")).length
  let jonasCount = uniq(map(jonas.nodes, "EXIF.DateCreatedISO")).length

  return (
    <Layout>
      <SEO title="A photo a day" />
      <section className="section people">
        <div className="column is-narrow has-text-centered">
          <h1 className="title is-1">
            <Link to={"/jonas"}>Jonas</Link>
          </h1>
          <h2 className="subtitle is-3">{jonasCount} days</h2>
        </div>
        <div className="column is-narrow has-text-centered">
          <h1 className="title is-1">
            <Link to={"/eva"}>Eva</Link>
          </h1>
          <h2 className="subtitle is-3">{evaCount} days</h2>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
export const query = graphql`
  query {
    eva: allS3ImageAsset(filter: { Key: { regex: "/^eva//" } }) {
      nodes {
        EXIF {
          DateCreatedISO
        }
      }
    }
    jonas: allS3ImageAsset(filter: { Key: { regex: "/^jonas//" } }) {
      nodes {
        EXIF {
          DateCreatedISO
        }
      }
    }
  }
`
